const blocos = {
  wpBlockImage: function () {
    $('.wp-block-image', document.body)
      .attr('class', 'text-center')
      .find('img')
      .attr('class', 'img-fluid img-thumbnail')
      .wrap(function () {
        if (
          $(this).parent().find('.lightbox-trigger').remove().length
        ) {
          $('.wp-lightbox-overlay').remove();
          return $('<a>').attr('href', $(this).attr('src'));
        }
        return;
      });
  },
  wpBlockTable: function () {
    $('.wp-block-table', document.body)
      .attr('class', 'table-responsive w-100')
      .find('table')
      .attr('class', 'table table-bordered table-striped table-hover')
      .find('thead,tfoot')
      .find('td,th')
      .attr('class', 'fw-bold');
  },
  wpElementCaption: function () {
    $('.wp-element-caption', document.body)
      .attr('class', 'text-center small fw-bold fst-italic')
      .text(function () {
        return $(this).text();
      });
  },

  /* wpBlockGallery: function () {
    $('.wp-block-gallery', document.body)
      .attr('class', 'swiper-wrapper')
      .wrap('<div class="swiper">')
      .after(
        '<div class="swiper-pagination"></div><div class="swiper-button-prev"></div><div class="swiper-button-next"></div>'
      )
      .find('figure')
      .attr('class', 'swiper-slide text-center');

    $(document).trigger('update.swiper');
  }, */


  wpBlockGallery: function () {

    $('.wp-block-gallery', document.body).each(function (i, g) {
      const $g = $(g);
      const id = `_s_${Math.floor(Math.random() * 999999) + 1}`;
      const $figures = $g.find('figure');

      // Configuração do Swiper principal
      $g.attr('class', 'swiper-wrapper mb-2')
        .wrap($('<div class="swiper">').data('swiper', {
          navigation: false,
          centerInsufficientSlides: true,
          centeredSlides: true,
          centeredSlidesBounds: true,
          spaceBetween: 0,
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          thumbs: {
            swiper: "#" + id
          },
          keyboard: {
            enabled: true,
            onlyInViewport: true,
          },
        }))
        .after('<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>')
        .find('figure')
        .attr('class', 'swiper-slide text-center');

      // Configuração do Swiper de miniaturas
      const $thumbSwiper = $('<div id="' + id + '" class="swiper mb-3">')
        .append($('<div class="swiper-wrapper">').html(
          $figures.map(function () {
            return `<div class="swiper-slide" style="width: 100px">
                  <div class="ratio ratio-1x1" role="button">
                    <img class="img-fluid img-thumbnail routed object-fit-cover" src="${$(this).find('img').attr('data-src') || $(this).find('img').attr('src')}" />
                  </div>
                </div>`;
          }).get().join('')
        ))
        .data('swiper', {
          navigation: false,
          pagination: false,
          spaceBetween: 10,
          freeMode: true,
          centerInsufficientSlides: true,
          slidesPerView: 'auto',
          keyboard: {
            enabled: true,
            onlyInViewport: true,
          },
        });

      $g.closest('.swiper').after($thumbSwiper);

      // Configuração das legendas
      $figures.find('figcaption')
        .attr('class', 'text-center small position-absolute w-100 bottom-0 text-light text-shadow-dark m-auto p-3')
        .text(function () {
          return $(this).text();
        });
    }).promise()
      .done((target) => {
        if (target.length) {
          $(document).trigger('update.swiper');
        }
      });
  },
  wpBlockQuote: function () {
    $('.wp-block-quote', document.body).attr(
      'class',
      'blockquote border-start border-3 ps-3'
    );
  },
  wpElementButton: function () {
    $('.wp-element-button', document.body).attr(
      'class',
      'btn btn-sm btn-outline-primary m-1'
    );
  },
  wpBlockFile: function () {

    $('object[type="application/pdf"][data$=".pdf"]', document.body)
      .filter(function () {
        return document.location.origin === new URL(this.data).origin;
      })
      .replaceWith(function () {
        return $('<iframe>').attr({
          src: $(this).attr('data'),
          style: $(this).attr('style'),
          loading: 'lazy',
        });
      }).promise()
      .done((target) => {
        if (target.length) {
          $(document).trigger('update.pdf');
        }
      });

  },
  migracao: function () {
    $('.the_content', document.body).each(function () {
      $('img:not(.img-fluid)', this)
        .addClass('img-fluid img-thumbnail')
        .wrap('<div class="text-center"></div>')
        .removeAttr('align');

      $('figcaption:not(.text-center)', this)
        .attr('class', 'text-center small fw-bold fst-italic')
        .text(function () {
          return $(this).text();
        });

      $('table:not(.table)', this)
        .addClass('table table-bordered table-striped table-hover')
        .wrap('<div class="table-responsive w-100"></div>');
    });
  },
};

function init() {
  jQuery(($) =>
    $(document)
      .on('update.blocos', (e,) =>
        Object.values(blocos).forEach((fn) => fn())
      )
      .trigger('update.blocos')
  );
}

if (typeof jQuery === 'function') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}
